import { Box, Container, Divider, Grid, Typography } from "@mui/material";
import React from "react";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";
import ContactForm from "../Components/ContactForm";
import location from "../Assets/Images/icons/location.svg";
import mail from "../Assets/Images/icons/mail.svg";
import phone from "../Assets/Images/icons/phone.svg";
import dot from "../Assets/Images/dot.gif";
import contact from "../Assets/Images/contact.png";
import frame from "../Assets/Images/frame.svg";

const ContactUs = () => {
  return (
    <Box>
      <Navbar />
      <Container>
        <Box py={"3rem"}>
          <Typography
            sx={{
              fontFamily: "exoBold",
              fontSize: { md: "28px", xs: "22px" },
              color: "#000",
            }}
          >
            Got a Challenge?
          </Typography>
          <Typography
            sx={{
              display: "inline",
              fontFamily: "exoBold",
              fontSize: { sm: "40px", xs: "30px" },
              background:
                "linear-gradient(to right,#E41111 0, #FF6666 22%, #53E71F 50%, #39BB0B 83%)",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
            }}
          >
            We’ve got solutions.
          </Typography>
          <Typography
            sx={{
              fontFamily: "exoBold",
              fontSize: { md: "28px", xs: "22px" },
              color: "#000",
            }}
          >
            Contact Us!
          </Typography>
          <Grid container spacing={3}>
            <Grid item md={6} sm={12} xs={12}>
              <ContactForm />
            </Grid>
            <Grid item md={6} sm={12} xs={12}>
              <Box position={"relative"} textAlign={"center"}>
                <Typography
                  component={"img"}
                  src={contact}
                  sx={{ width: { md: "80%", xs: "100%" } }}
                />
                <Box
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "0",
                    transform: "translateY(-50%)",
                  }}
                >
                  <Box
                    sx={{
                      position: "relative",
                      top: { sm: "0", xs: "10rem" },
                      background:
                        "linear-gradient(to right, rgba(228, 17, 17, 0.3) 0%, rgba(255, 102, 102, 0.3) 22%, rgba(83, 231, 31, 0.3) 50%, rgba(57, 187, 11, 0.3) 83%)",
                      p: "4rem 2rem",
                      borderRadius: "10px",
                      display: "inline-block",
                    }}
                  >
                    <Typography
                      component={"img"}
                      src={dot}
                      sx={{
                        position: "absolute",
                        top: "-50px",
                        width: "100px",
                        left: "50%",
                        transform: "translateX(-50%)",
                      }}
                    />
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                        gap: "2rem",
                        pt: "0.5rem",
                        color: "#0048D4",
                        fontFamily: "exoRegular",
                        fontSize: "16px",
                        "& div": {
                          display: "flex",
                          alignItems: "center",
                          gap: "0.5rem",
                          lineHeight: "0",
                        },
                        "& span": {
                          mt: "0.2rem",
                        },
                      }}
                    >
                      <Box>
                        {" "}
                        <Typography component={"img"} src={mail} />{" "}
                        <span>info@ingenuityanalytics.com</span>
                      </Box>
                      <Box>
                        {" "}
                        <Typography component={"img"} src={phone} />{" "}
                        <span>+2320405509744</span>
                      </Box>
                      <Box>
                        {" "}
                        <Typography component={"img"} src={phone} />{" "}
                        <span>+4802696353135</span>
                      </Box>
                      <Box>
                        {" "}
                        <Typography component={"img"} src={location} />{" "}
                        <span>780 Lynnway, Lynn MA 1905</span>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box
          py={"2rem"}
          sx={{
            mt: { sm: "0", xs: "6rem" },
            fontFamily: "exoMedium",
            fontSize: { sm: "18px", xs: "15px" },
            color: "rgb(0 0 0 / 69%)",
            "& .MuiDivider-root::before, .MuiDivider-root::after": {
              borderTop: "1px dashed #39BB0B",
              // width: { sm: "100%", xs: "15%" },
            },
            // "& .MuiDivider-root": {
            //   whiteSpace: { sm: "nowrap", xs: "balance" },
            // },
            // "& .MuiDivider-wrapper": {
            //   px: { sm: "auto", xs: "0" },
            // },
          }}
        >
          <Divider>
            <Typography component={"img"} src={frame} />
          </Divider>
        </Box>
      </Container>
      <Footer />
    </Box>
  );
};

export default ContactUs;
