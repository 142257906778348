import * as React from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
// import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import logo from "../Assets/Images/IA.png";
import ham_icon from "../Assets/Images/ham_icon.svg";
import contact from "../Assets/Images/contact.svg";
import contact_ from "../Assets/Images/contact_.svg";
// import user from "../Assets/Images/user.png";
import { Link, NavLink, useLocation } from "react-router-dom";
import MobileNav from "./MobileNav";
import { Button, Container } from "@mui/material";

const drawerWidth = 300;

function DrawerAppBar(props) {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const referer = searchParams.get("referer");
  React.useEffect(() => {
    if (referer) {
      localStorage.setItem("referer", referer);
    }
  }, [referer]);

  const navLinkStyles = ({ isActive }) => {
    return {
      color: isActive ? "#000" : "#000",
      // padding: '0rem 0.8rem',
      borderRadius: "8px",
      fontSize: "16px",
      display: "flex",
      transition: "0.5s",
      fontFamily: "exoMedium",
      textDecoration: "none",
      alignItems: "center",
      // fontWeight: isActive ? '700' : '500',
    };
  };

  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    <MobileNav handleDrawerToggle={handleDrawerToggle} cartValue={0} />
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Container
      sx={{
        py: { sm: "0.4rem", xs: "0.4rem" },
        position: "sticky",
        top: "0",
        zIndex: "1200",
      }}
    >
      <CssBaseline />
      <AppBar
        component="nav"
        sx={{
          position: "inherit",
          backgroundColor: "transparent",
          boxShadow: "none",
          backdropFilter: "blur(20px)",
          borderRadius: "10px",
        }}
      >
        <Toolbar
          sx={{ justifyContent: "space-between", p: "10px 15px !important" }}
        >
          <Link to={"/"} style={{ lineHeight: "0", textDecoration: "none" }}>
            {" "}
            {/* <Typography variant="h5" color={"#fff"} fontFamily={"lato"} fontWeight={600}>LOGO</Typography> */}
            <Typography
              component={"img"}
              src={logo}
              sx={{
                width: { sm: "220px", xs: "180px" },
              }}
            />
          </Link>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ display: { lg: "none", color: "#000" }, p: "0" }}
          >
            <Typography component={"img"} src={ham_icon} />
          </IconButton>

          <Box
            sx={{
              display: { md: "none", lg: "flex", sm: "none", xs: "none" },
              gap: "1.2rem",
              alignItems:"center"
            }}
          >
            <NavLink className="nav-link" style={navLinkStyles} to={"/"}>
              Home
            </NavLink>
            <NavLink className="nav-link" style={navLinkStyles} to={"/services"}>
              Services
            </NavLink>
            <NavLink className="nav-link" style={navLinkStyles} to={"/about"}>
              About us
            </NavLink>
            <Button component={Link} to="/contact"
              sx={{
                textTransform: "capitalize",
                fontFamily: "exoBold",
                // lineHeight:"0",
                background: "#39BB0B",
                borderRadius: "5px",
                color: "#fff",
                fontSize: "16px",
                p: "8px 30px",
                gap: "0.5rem",
                "&:hover": {
                  background: "#53E71F",
                  "& .hover":{
                    display:"block"
                  },
                  "& .main":{
                    display:"none"
                  },
                },
                "& .hover":{
                  display:"none"
                },
              }}
            >
              <Typography component={"img"} src={contact_} width={"18px"} className="hover"/>{" "}
              <Typography component={"img"} src={contact} width={"18px"} className="main"/>{" "}
              Contact us
            </Button>
          </Box>
        </Toolbar>
      </AppBar>
      <nav>
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { md: "block", lg: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              background:"#2A3027",
              padding: "1rem",
            },
          }}
        >
          {drawer}
        </Drawer>
      </nav>
    </Container>
  );
}

DrawerAppBar.propTypes = {
  window: PropTypes.func,
};

export default DrawerAppBar;
