import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import submit from "../Assets/Images/submit.svg";
import SuccessModal from "./SuccessModal";
import { API_URl } from "../config/config";
import axios from "axios";

const AboutForm = () => {
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [loading, setLoading] = useState(false);
  const formik = useFormik({
    initialValues: {
        fname:"",
        lname:"",
      email: "",
      phone: "",
      message:""
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .trim()
        .email("Invalid email address")
        .required("Email is required"),
      fname: Yup.string()
        .trim()
        .required("First name is required"),
      lname: Yup.string()
        .trim()
        .required("Last name is required"),
      phone: Yup.number()
        .required("Last name is required"),
      message: Yup.string()
        .trim()
        .required("Message is required")
    }),
    onSubmit: async (
        values,
        { setErrors, setSubmitting, resetForm, errors }
      ) => {
     
  
        if (errors) {
          setErrors(errors);
        }
        const config = {
          headers: {
            "Content-Type": "application/json",
          },
        };
       
        try {
          setLoading(true);
          const res = await axios.post(
            `${API_URl}/register`,
            values,
            config
          );
          if (res.status === 200) {
            setShowSnackbar(true);
            setTimeout(() => {
              resetForm();
              setLoading(false);
              setShowSnackbar(false);
            }, 2000);
          }
        } catch (err) {
          setLoading(false);
          console.log(err);
        }
      }
  });
  return (
    <Box
      sx={{
        "& .bin": {
          width: { xs: "100%" },
        //   height: "100%",
          borderRadius: "8px",
          border: "1px solid transparent",
          background: "#F1FAFF",
          color: "#fff",
          "&:focus-Visible": {
            outline: "none !important",
            border: "none !important",
          },
          "&:hover": {
            border: "1px solid transparent",
          },
          "& .MuiInputBase-root": {
            "&::before": {
              display: "none",
            },
            "&::after": {
              display: "none",
            },
          },

          "&:focus": {
            boxShadow: "none !important",
            border: "1px solid transparent !important",
            borderRadius: "10px !important ",
          },
          "& input": {
            padding: "15px",
            width: "100%",
            fontFamily: "exoRegular",
            "&:focus": {
              outline: "none",
            },
            "&::placeholder": {
              color: "#828789 !important",
              fontFamily: "exoRegular",
              // textAlign: "center",
              fontSize: "16px",
            },
          },
        },
        "& .err": {
          marginTop: "0.2rem",
          fontSize: "12px !important",
          color: "red !important",

        },
        "& button": {
          color: "#fff !important",
          padding: "0.4rem 2rem !important",
          fontSize: { sm: "16px", xs: "14px" },
          borderRadius: "8px",
          width: { lg: "200px" },
          fontFamily: "exoRegular",
          textTransform: "capitalize",
          fontWeight: "600",
          "&.Mui-disabled": {
            cursor: "not-allowed !important",
            pointerEvents: "auto !important",
            color: "rgb(255 255 255 / 38%) !important",
            "&:hover": {
              opacity: "1",
            },
          },
        },
      }}
    >
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2}>
          <Grid item md={6} sm={6} xs={12}>
              <TextField
                autoComplete="off"
                fullWidth
                variant="standard"
                type="text"
                className="bin"
                placeholder="First name"
                id="fname"
                name="fname"
                value={formik.values.fname}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.fname && formik.errors.fname && (
                <Typography variant="body1" className="err">
                  {formik.errors.fname}
                </Typography>
              )}
              </Grid>
            <Grid item md={6} sm={6} xs={12}>
              <TextField
                autoComplete="off"
                fullWidth
                variant="standard"
                type="text"
                className="bin"
                placeholder="Last name"
                id="lname"
                name="lname"
                value={formik.values.lname}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.lname && formik.errors.lname && (
                <Typography variant="body1" className="err">
                  {formik.errors.lname}
                </Typography>
              )}
            </Grid>
            <Grid item md={6} sm={6} xs={12}>
              <TextField
                autoComplete="off"
                fullWidth
                variant="standard"
                type="text"
                className="bin"
                placeholder="Phone number"
                id="phone"
                name="phone"
                value={formik.values.phone}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.phone && formik.errors.phone && (
                <Typography variant="body1" className="err">
                  {formik.errors.phone}
                </Typography>
              )}
            </Grid>
            <Grid item md={6} sm={6} xs={12}>
              <TextField
                autoComplete="off"
                fullWidth
                variant="standard"
                type="email"
                className="bin"
                placeholder="Email Id"
                id="email"
                name="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.email && formik.errors.email && (
                <Typography variant="body1" className="err">
                  {formik.errors.email}
                </Typography>
              )}
            </Grid>
         
          <Grid item md={12} sm={6} xs={12}>
            <Box height={"100%"}>
              <TextField
                autoComplete="off"
                fullWidth
                variant="standard"
                type="text"
                className="bin"
                placeholder="Message..."
                id="message"
                name="message"
                value={formik.values.message}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.message && formik.errors.message && (
                <Typography variant="body1" className="err">
                  {formik.errors.message}
                </Typography>
              )}
            </Box>
          </Grid>
          <Grid item md={12} sm={12} xs={12} textAlign={"end"}>
            <Button
              className="btn"
              sx={{
                background: "#39BB0B",
                border: "1px solid transparent",
                "&:hover": {
                  background: "#39BB0B",
                },
              }}
              type="submit"
            >
              <Typography
                component={"img"}
                src={submit}
                sx={{ mr: "0.3rem", mt: "-1px", width: "18px" }}
              />{" "}
             {loading?"Submitting..":"Submit"}
            </Button>
          </Grid>
        </Grid>
      </form>
      {/* ////success modal  */}
      <SuccessModal setShowSnackbar={setShowSnackbar} showSnackbar={showSnackbar}/>
    </Box>
  );
};

export default AboutForm;
