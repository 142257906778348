import { Box, Button, Dialog, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import textbg from "../Assets/Images/textbg.png";
import { useFormik } from "formik";
import * as Yup from "yup";
import email from "../Assets/Images/email.svg";
import Group from "../Assets/Images/Group.svg";
import subscribe_bg from "../Assets/Images/subscribe_bg.png";
import { API_URl } from "../config/config";
import axios from "axios";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import PropTypes from "prop-types";

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: "12px 16px 0", color: "#800080" }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          title="Close"
          sx={{
            position: "absolute",
            right: "-10px",
            color: "#fff",
            top: "-10px",
            background: "#39BB0B",
            // border:"1px solid #800080",
            borderRadius: "100%",
            "&:hover": {
              background: "#39BB0B",
            },
            // color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon sx={{ fontSize: "1.3rem" }} />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const SubscribeBox = () => {
  const [loading, setLoading] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .trim()
        .email("Invalid email address")
        .required("Email is required"),
    }),
    onSubmit: async (
      values,
      { setErrors, setSubmitting, resetForm, errors }
    ) => {
      if (errors) {
        setErrors(errors);
      }
      setLoading(true)
      let url = `${API_URl}/subscribe`;
      axios
        .post(url, values)
        .then((response) => {
          // Handle success
          if (response.status === 200) {
            setLoading(false)
            setShowSnackbar(true);
            setTimeout(() => {
              setShowSnackbar(false);
            }, 4000);
          }
        })
        .catch((error) => {
          // Handle error
          setLoading(false)
          console.error("Error:", error.message);
        });
      resetForm();
    },
  });
  return (
    <>
      <Typography
        component={"img"}
        src={Group}
        sx={{
          width:{sm:"auto",xs:"40%"},
          position: "relative",
          top: "4rem",
          left: "15%",
          transform: "translateX(-15%)",
          zIndex: "-1",
        }}
      />
      <Box
        textAlign={"center"}
        py={"9rem"}
        sx={{
          backgroundImage: `url(${subscribe_bg})`,
          backgroundSize: "cover", // Adjust as needed (contain, cover, etc.)
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Typography
            sx={{
              backgroundImage: `url(${textbg})`,
              backgroundSize: "cover", // Adjust as needed (contain, cover, etc.)
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              p: "10px 20px",
              borderLeft: "6px solid #E41111",
              borderRight: "6px solid #39BB0B",
              lineHeight: "0",
            }}
          >
            <Typography
              component={"span"}
              sx={{
                fontFamily: "exoBold",
                fontSize: { sm: "30px", xs: "22px" },
                background:
                  "linear-gradient(to right,#E41111 0, #FF6666 22%, #53E71F 50%, #39BB0B 83%)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
              }}
            >
              Subscribe to our newsletter
            </Typography>
          </Typography>
        </Box>
        <Typography
          sx={{
            fontFamily: "exoRegular",
            fontSize: "26px",
            py: "1.5rem",
          }}
        >
          Stay up to date with our latest news and updates.
        </Typography>
        <form onSubmit={formik.handleSubmit}>
          <Box
            sx={{
              display: "flex",
              p: "1rem",
              gap: "1rem",
              flexDirection: { sm: "row", xs: "column" },
              justifyContent: "center",
              "& button": {
                color: "#fff !important",
                padding: "0.4rem 2rem !important",
                fontSize: { sm: "16px", xs: "14px" },
                borderRadius: "8px",
                width: { lg: "200px" },
                fontFamily: "exoRegular",
                textTransform: "capitalize",
                fontWeight: "600",
                "&.Mui-disabled": {
                  cursor: "not-allowed !important",
                  pointerEvents: "auto !important",
                  color: "rgb(255 255 255 / 38%) !important",
                  "&:hover": {
                    opacity: "1",
                  },
                },
              },
            }}
          >
            <TextField
              autoComplete="off"
              //   fullWidth
              variant="standard"
              type="email"
              sx={{
                width: { lg: "25%", md: "50%", xs: "100%" },
                borderRadius: "8px",
                border: "1px solid transparent",
                background: "#fff",
                color: "#fff",
                "&:focus-Visible": {
                  outline: "none !important",
                  border: "none !important",
                },
                "&:hover": {
                  border: "1px solid transparent",
                },
                "& .MuiInputBase-root": {
                  "&::before": {
                    display: "none",
                  },
                  "&::after": {
                    display: "none",
                  },
                },

                "&:focus": {
                  boxShadow: "none !important",
                  border: "1px solid transparent !important",
                  borderRadius: "10px !important ",
                },
                "& input": {
                  padding: "10px 15px",
                  width: "100%",
                  fontFamily: "exoRegular",
                  "&:focus": {
                    outline: "none",
                  },
                  "&::placeholder": {
                    color: "#0000008f !important",
                    fontFamily: "exoRegular",
                    // textAlign: "center",
                    fontSize: "16px",
                  },
                },
              }}
              placeholder="Enter your email address"
              id="email"
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />

            <Button
              sx={{
                background: "#39BB0B",
                border: "1px solid transparent",
                "&:hover": {
                  background: "#39BB0B",
                },
              }}
              type="submit"
            >
              <Typography
                component={"img"}
                src={email}
                sx={{ mr: "0.3rem", mt: "-1px", width: "18px" }}
              />{" "}
              {loading?"Subscribing...":"Subscribe"}
            </Button>
          </Box>
          {formik.touched.email && formik.errors.email && (
            <Typography
              variant="body1"
              sx={{
                marginTop: "0.2rem",
                fontSize: "12px !important",
                color: "red !important",
                textAlign: "center",
              }}
            >
              {formik.errors.email}
            </Typography>
          )}
        </form>
      </Box>
      <Dialog
            onClose={() => setShowSnackbar(false)}
            aria-labelledby="customized-dialog-title"
            open={showSnackbar}
            sx={{
              "& .MuiDialogContent-root": {
                padding: "1rem",
                border: "none",
              },
              "& .MuiDialogActions-root": {
                padding: "1rem",
              },
              "& .MuiPaper-root": {
                overflowY: "inherit",
              },
              "& .MuiDialog-paper": {
                width: "500px",
                maxWidth: "1222px",
                // background: "#E4D5F4",
                border: "2px solid #39BB0B",
                borderRadius: "10px",
              },
              "& .MuiDialog-container": {
                backdropFilter: "blur(5px)",
              },
            }}
          >
            <BootstrapDialogTitle
              id="customized-dialog-title"
              onClose={() => setShowSnackbar(false)}
            >
              <Typography
                sx={{
                  color: "green",
                  fontSize: "1.2rem",
                  borderBottom: "1px solid #39BB0B",
                  pb: "0.3rem",
                  mb: "0.3rem",
                  display: "flex",
                  alignItems: "center",
                  gap: "0.1rem",
                }}
              >
                <TaskAltIcon sx={{ mb: "2px" }} />
                Success
              </Typography>
            </BootstrapDialogTitle>
            <DialogContent dividers>
              <Box
                sx={{
                  textAlign: "center",
                  py: "1.5rem",
                  //   fontSize: "1.2rem",
                  color: "green",
                }}
              >
                Thanks for subscribing..
              </Box>
            </DialogContent>
          </Dialog>
    </>
  );
};

export default SubscribeBox;
