import { Box, Container, Grid, Typography } from "@mui/material";
import React from "react";
import fb from "../Assets/Images/icons/fb.svg";
import home from "../Assets/Images/icons/home.svg";
import about from "../Assets/Images/icons/info.svg";
import insta from "../Assets/Images/icons/insta.svg";
import location from "../Assets/Images/icons/location.svg";
import mail from "../Assets/Images/icons/mail.svg";
import phone from "../Assets/Images/icons/phone.svg";
import services from "../Assets/Images/icons/services.svg";
import x from "../Assets/Images/icons/x.svg";
import logo from "../Assets/Images/IA.png";

const Footer = () => {
  return ( 
    <Box
      sx={{
        background: "#2A3027",
      }}
    >
      <Container>
        <Box py={"3rem"} px={"1rem"}>
          <Grid container spacing={6}>
            <Grid item md={3} xs={12}>
              <Box
                sx={{
                  textAlign: { md: "start", xs: "center" },
                }}
              >
                <Typography
                  component={"img"}
                  src={logo}
                  sx={{
                    width: { sm: "250px", xs: "160px" },
                  }}
                />
                <Typography
                  sx={{
                    pt: "1rem",
                    lineHeight: "30px",
                    fontFamily: "exoRegular",
                    fontSize: { md: "16px", xs: "16px" },
                    color: "#fff",
                  }}
                >
                  Get in touch with Ingenuity Analytics to discover how we can
                  help your business leverage the latest technologies for growth
                  and success.
                </Typography>
              </Box>
            </Grid>
            <Grid
              item
              md={1}
              xs={12}
              textAlign={"center"}
              sx={{
                display: { md: "block", xs: "none" },
              }}
            >
              <Box
                sx={{
                  width: "3px",
                  background: "#4F4F4F",
                  height: "100%",
                  m: "0 auto",
                }}
              ></Box>
            </Grid>

            <Grid item md={8} xs={12}>
              <Grid container spacing={2}>
                <Grid item md={8} sm={8} xs={12}>
                  <Grid container spacing={2}>
                    <Grid
                      item
                      md={6}
                      xs={6}
                      sx={{ textAlign: { sm: "start", xs: "center" } }}
                    >
                      <Typography
                        sx={{
                          pt: "1rem",
                          fontFamily: "exoBold",
                          fontSize: { md: "24px", xs: "24px" },
                          color: "#fff",
                        }}
                      >
                        Site Map
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          flexDirection: "column",
                          alignItems: { sm: "start", xs: "center" },
                          gap: "1rem",
                          pt: "0.5rem",
                          color: "rgb(255 255 255 / 60%)",
                          fontFamily: "exoRegular",
                          fontSize: "16px",
                          "& div": {
                            display: "flex",
                            alignItems: "center",
                            gap: "0.5rem",
                            lineHeight: "0",
                          },
                          "& span": {
                            mt: "0.2rem",
                          },
                        }}
                      >
                        <Box>
                          {" "}
                          <Typography component={"img"} src={home} />{" "}
                          <span>Home</span>
                        </Box>
                        <Box>
                          {" "}
                          <Typography component={"img"} src={services} />{" "}
                          <span>Services</span>
                        </Box>
                        <Box>
                          {" "}
                          <Typography component={"img"} src={about} />{" "}
                          <span>About us</span>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item md={6} xs={6}>
                      <Typography
                        sx={{
                          pt: "1rem",
                          fontFamily: "exoBold",
                          fontSize: { md: "24px", xs: "24px" },
                          color: "#fff",
                          textAlign: { sm: "start", xs: "center" },
                        }}
                      >
                        Contact
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          flexDirection: "column",
                          alignItems: { sm: "start", xs: "center" },
                          gap: "1rem",
                          pt: "0.5rem",
                          color: "rgb(255 255 255 / 60%)",
                          fontFamily: "exoRegular",
                          fontSize: "16px",
                          "& div": {
                            display: "flex",
                            alignItems: "center",
                            gap: "0.5rem",
                            lineHeight: "0",
                          },
                          "& span": {
                            mt: "0.2rem",
                          },
                        }}
                      >
                        <Box>
                          {" "}
                          <Typography component={"img"} src={mail} />{" "}
                          <span>Email</span>
                        </Box>
                        <Box>
                          {" "}
                          <Typography component={"img"} src={phone} />{" "}
                          <span>Phone number</span>
                        </Box>
                        <Box>
                          {" "}
                          <Typography component={"img"} src={location} />{" "}
                          <span>Location</span>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item md={4} sm={4} xs={12} textAlign={"center"}>
                  <Typography
                    sx={{
                      pt: "1rem",
                      fontFamily: "exoBold",
                      fontSize: { md: "24px", xs: "24px" },
                      color: "#fff",
                    }}
                  >
                    Stay in touch
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      gap: "2rem",
                      pt: "0.5rem",
                    }}
                  >
                    <Typography component={"img"} src={fb} />
                    <Typography component={"img"} src={insta} />
                    <Typography component={"img"} src={x} />
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Container>
      <Box
        sx={{
          background: "#4F4F4F",
        }}
      >
        <Container>
          <Box
            sx={{
              display: "flex",
              justifyContent: { sm: "space-between", xs: "center" },
              flexWrap: "wrap",
              py: "1rem",
              "& p": {
                py: ".2rem",
                fontSize: "14px",
                textAlign: { sm: "auto", xs: "center" },
              },
            }}
          >
            <Typography
              sx={{
                fontFamily: "exoRegular",
                fontSize: { md: "16px", xs: "16px" },
                color: "rgb(255 255 255 / 60%)",
                mb:{sm:"0",xs:"0.5rem"}
              }}
            >
              @2024 Ingenuity Analytics all rights reserved
            </Typography>
            <Box
              sx={{
                display: "flex",
                gap: "1rem",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "exoRegular",
                  fontSize: { md: "16px", xs: "16px" },
                  color: "rgb(255 255 255 / 60%)",
                }}
              >
                Terms and Conditions
              </Typography>
              <Typography
                sx={{ borderLeft: "3px solid #2A3027", height: "100%" }}
              />
              <Typography
                sx={{
                  fontFamily: "exoRegular",
                  fontSize: { md: "16px", xs: "16px" },
                  color: "rgb(255 255 255 / 60%)",
                }}
              >
                Terms and Conditions
              </Typography>
            </Box>
          </Box>
        </Container>
      </Box>
    </Box>
  );
};

export default Footer;
