import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import submit from "../Assets/Images/submit.svg";
import { API_URl } from "../config/config";
import axios from "axios";
import SuccessModal from "./SuccessModal";


const ContactForm = () => {
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [loading, setLoading] = useState(false);
  const formik = useFormik({
    initialValues: {
      fname:"",
        lname:"",
      email: "",
      phone: "",
      message: "",
    },
    validationSchema: Yup.object({
      fname: Yup.string()
      .trim()
      .required("First name is required"),
    lname: Yup.string()
      .trim()
      .required("Last name is required"),
      email: Yup.string()
        .trim("Email should not contain spaces")
        .email("Invalid email")
        .required("Email is required"),
      message: Yup.string()
        .trim("Message should not contain spaces")
        .required("Message is required"),
      phone: Yup.number().required("Phone number is required"),
    }),
    onSubmit: async (
      values,
      { setErrors, setSubmitting, resetForm, errors }
    ) => {
      console.log(values);

      if (errors) {
        setErrors(errors);
      }
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
     
      try {
        setLoading(true);
        const res = await axios.post(
          `${API_URl}/register`,
          values,
          config
        );
        if (res.status === 200) {
          setShowSnackbar(true);
          setTimeout(() => {
            resetForm();
            setLoading(false);
            setShowSnackbar(false);
          }, 2000);
        }
      } catch (err) {
        setLoading(false);
        console.log(err);
      }
    }
  });
  return (
    <Box
      py={"2rem"}
      sx={{
        "& .bin1": {
          borderBottom: "1px solid #CCCCCC !important",
          //   boxShadow: "0px 2px 8px 0px rgba(0, 0, 0, 0.15)",
          width: "100%",
          background: "transparent",
          color: "#fff",
          "& input[type=number]": {
            "-moz-appearance": "textfield",
          },
          "& input[type=number]::-webkit-outer-spin-button": {
            "-webkit-appearance": "none",
            margin: 0,
          },
          "& input[type=number]::-webkit-inner-spin-button": {
            "-webkit-appearance": "none",
            margin: 0,
          },
          "&:focus-Visible": {
            outline: "none !important",
            border: "none !important",
          },
          "&:hover": {
            borderBottom: "1px solid #CCCCCC !important",
          },
          "& .MuiInputBase-root": {
            "&::before": {
              display: "none",
            },
            "&::after": {
              display: "none",
            },
          },

          "&:focus": {
            boxShadow: "none !important",
            border: "1px solid #fff !important",
            borderRadius: "10px !important ",
          },
          "& input": {
            //   padding: "12px 20px",
            width: "100%",
            color: "#000",
            fontSize: "15px",
            // fontWeight: "600",
            fontFamily: "exoRegular !important",
            "&:focus": {
              outline: "none",
            },
            "&::placeholder": {
              color: "#0000008f !important",
              fontFamily: "exoRegular !important",
            },
          },
        },
        "& .error": {
          marginTop: "0.4rem !important",
          fontSize: "13px !important",
          color: "red !important",
          fontFamily: "exoRegular !important",
          textAlign: "start",
        },
        "& .btn": {
          color: "#fff !important",
          padding: "0.4rem 2rem !important",
          fontSize: { sm: "16px", xs: "14px" },
          borderRadius: "8px",
          width: { lg: "200px" },
          fontFamily: "exoBold",
          textTransform: "capitalize",
          fontWeight: "600",
          "&.Mui-disabled": {
            cursor: "not-allowed !important",
            pointerEvents: "auto !important",
            color: "rgb(255 255 255 / 38%) !important",
            "&:hover": {
              opacity: "1",
            },
          },
        },
      }}
    >
      <Grid item md={12} sm={12} xs={12}>
        <form onSubmit={formik.handleSubmit}>
          <Box>
            <Grid container spacing={2}>
              <Grid item md={12} sm={12} xs={12} mb={"1.2rem"}>
                <TextField
                  autoComplete="off"
                  fullWidth
                  id="fname"
                  name="fname"
                  type="text"
                  className="bin1"
                  placeholder="Fisrt name"
                  variant="standard"
                  value={formik.values.fname}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.fname && formik.errors.fname && (
                  <Typography variant="body1" className="error">
                    {formik.errors.fname}
                  </Typography>
                )}
              </Grid>
              <Grid item md={12} sm={12} xs={12} mb={"1.2rem"}>
                <TextField
                  autoComplete="off"
                  fullWidth
                  id="lname"
                  name="lname"
                  type="text"
                  className="bin1"
                  placeholder="Last name"
                  variant="standard"
                  value={formik.values.lname}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.lname && formik.errors.lname && (
                  <Typography variant="body1" className="error">
                    {formik.errors.lname}
                  </Typography>
                )}
              </Grid>

              <Grid item md={12} sm={12} xs={12} mb={"1.2rem"}>
                <TextField
                  autoComplete="off"
                  fullWidth
                  id="email"
                  name="email"
                  type="email"
                  className="bin1"
                  placeholder="Email id"
                  variant="standard"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.email && formik.errors.email && (
                  <Typography variant="body1" className="error">
                    {formik.errors.email}
                  </Typography>
                )}
              </Grid>
              <Grid item md={12} sm={12} xs={12} mb={"1.2rem"}>
                <TextField
                  autoComplete="off"
                  fullWidth
                  id="phone"
                  name="phone"
                  type="number"
                  className="bin1"
                  placeholder="Phone No."
                  variant="standard"
                  value={formik.values.phone}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.phone && formik.errors.phone && (
                  <Typography variant="body1" className="error">
                    {formik.errors.phone}
                  </Typography>
                )}
              </Grid>
              <Grid item md={12} sm={12} xs={12} mb={"1rem"}>
                <TextField
                  autoComplete="off"
                  fullWidth
                  id="message"
                  name="message"
                  type="text"
                  className="bin1"
                  placeholder="Message"
                  variant="standard"
                  // multiline={6}
                  value={formik.values.message}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.message && formik.errors.message && (
                  <Typography variant="body1" className="error">
                    {formik.errors.message}
                  </Typography>
                )}
              </Grid>
              {/* <Grid item md={12} sm={12} xs={12}>
                <Typography
                  sx={{
                    fontSize: "15px",
                    color: "#828789",
                    fontFamily: "exoRegular ",
                  }}
                >
                  I agree to the collection and processing of my personal data
                  by Ingenuity Analytics to receive information regarding their
                  services.
                </Typography>
              </Grid> */}
            </Grid>

            <Box my={"1rem"}>
              <Button
                className="btn"
                sx={{
                  background: "#39BB0B",
                  border: "1px solid transparent",
                  "&:hover": {
                    background: "#39BB0B",
                  },
                }}
                type="submit"
              >
                <Typography
                  component={"img"}
                  src={submit}
                  sx={{ mr: "0.3rem", mt: "-1px", width: "18px" }}
                />{" "}
                {loading?"Submitting..":"Submit"}
              </Button>
            </Box>
          </Box>
        </form>
      </Grid>
      {/* ////success modal  */}
      <SuccessModal setShowSnackbar={setShowSnackbar} showSnackbar={showSnackbar}/>
   
    </Box>
  );
};

export default ContactForm;
