import { Box, Button, Container, Divider, Grid, Typography } from "@mui/material";
import React from "react";
import Navbar from "../../Components/Navbar";
import Footer from "../../Components/Footer";
import textbg from "../../Assets/Images/textbg.png";
import service_hero_bg from "../../Assets/Images/service_hero_bg.png";
import service_msg_bg from "../../Assets/Images/service_msg_bg.png";
import arrow from "../../Assets/Images/arrow.gif";
import vision_bg from "../../Assets/Images/vision_bg.png";
import robotics_bg from "../../Assets/Images/robotics_bg.png";
import Robotic_Img from "../../Assets/Images/Robotic_Img.png";
import Web_img from "../../Assets/Images/Web_img.png";
import Data_science_Img from "../../Assets/Images/Data_science_Img.png";
import data_science from "../../Assets/Images/data_science.svg";
import robotics from "../../Assets/Images/robotics.svg";
import AboutForm from "../../Components/AboutForm";
import contact from "../../Assets/Images/contact.svg";
import contact_ from "../../Assets/Images/contact_.svg";
import web3 from "../../Assets/Images/web3.svg";
import Web3_tools from "../../Assets/Images/Web3_tools.gif";
import Robotic_tools from "../../Assets/Images/Robotic_tools.gif";
import Data_science_tools from "../../Assets/Images/Data_science_tools.gif";
import AchievmentBox from "../../Components/AchievmentBox";

const Services = () => {
  return (
    <Box>
      <Navbar />
      <Box
        sx={{
          "& button": {
            mt: "1rem",
            textTransform: "capitalize",
            fontFamily: "exoBold",
            // lineHeight:"0",
            background: "#39BB0B",
            borderRadius: "5px",
            color: "#fff",
            fontSize: "16px",
            p: "8px 30px",
            gap: "0.5rem",
            "&:hover": {
              background: "#53E71F",
              "& .hover": {
                display: "block",
              },
              "& .main": {
                display: "none",
              },
            },
            "& .hover": {
              display: "none",
            },
          },
        }}
      >
        <Container>
          <Box
            sx={{
              backgroundImage: `url(${service_hero_bg})`,
              backgroundSize: "cover", // Adjust as needed (contain, cover, etc.)
              backgroundPosition: "100% 100%",
              backgroundRepeat: "no-repeat",
              py: "2rem",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Typography
                sx={{
                  backgroundImage: `url(${textbg})`,
                  backgroundSize: "cover", // Adjust as needed (contain, cover, etc.)
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                  p: "10px 20px",
                  borderLeft: "6px solid #E41111",
                  borderRight: "6px solid #39BB0B",
                  lineHeight: "0",
                }}
              >
                <Typography
                  component={"span"}
                  sx={{
                    fontFamily: "exoBold",
                    fontSize: { sm: "30px", xs: "25px" },
                    background:
                      "linear-gradient(to right,#E41111 0, #FF6666 22%, #53E71F 50%, #39BB0B 83%)",
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                  }}
                >
                  Our Innovative Solutions
                </Typography>
              </Typography>
            </Box>
            <Typography
              sx={{
                fontFamily: "FRAHV",
                letterSpacing: { sm: "10px", xs: "7px" },
                textAlign: "center",
                fontSize: { md: "42px", sm: "38px", xs: "25px" },
                textFillColor: "transparent",
                textStroke: "2px #7D7D7D",
                WebkitTextFillColor: "transparent",
                WebkitTextStroke: "2px #7D7D7D",
                MozTextFillColor: "transparent",
                py: "2rem",
                wordBreak: "break-all",
                "& br": {
                  display: { sm: "none", xs: "block" },
                },
              }}
            >
              YOUR PARTNER IN DIGITAL <br />
              TRANSFORMATION!
            </Typography>
            <Typography
              sx={{
                fontFamily: "exoRegular",
                fontSize: { sm: "20px", xs: "20px" },
                color: "rgb(0 0 0 / 69%)",
                textAlign: { xs: "center" },
                width: { sm: "70%", xs: "90%" },
                m: "0 auto",
              }}
            >
              Discover how Ingenuity Analytics can transform your business with
              innovative solutions. Our expertise ensures you ahead in the
              digital age with enhanced efficiency, productivity and actionable
              insights.
            </Typography>
          </Box>
        </Container>
        <Box mt={"2rem"} sx={{
           background:
                "linear-gradient(to right, rgba(228, 17, 17, 0.3) 0%, rgba(255, 102, 102, 0.3) 22%, rgba(83, 231, 31, 0.3) 50%, rgba(57, 187, 11, 0.3) 83%)",
        }}>
       <Container>
       <Box
       pt={"2rem"}
            sx={{
             
              fontFamily: "exoMedium",
              fontSize: { sm: "18px", xs: "15px" },
              color: "rgb(0 0 0 / 69%)",
              "& .MuiDivider-root::before, .MuiDivider-root::after": {
                borderTop: "1px dashed #39BB0B",
                // width: { sm: "100%", xs: "15%" },
              },
              // "& .MuiDivider-root": {
              //   whiteSpace: { sm: "nowrap", xs: "balance" },
              // },
              // "& .MuiDivider-wrapper": {
              //   px: { sm: "auto", xs: "0" },
              // },
            }}
          >
            <Divider>
            <Typography
                    sx={{
                      fontFamily: "exoBold",
                      fontSize: { sm: "20px", xs: "20px" },
                      color: "rgb(0 0 0 / 100%)"
                    }}
                  >
                  See What We Can Achieve Together
                  </Typography>
            </Divider>
          </Box>
          <Box>
            <AchievmentBox/>
          </Box>
       </Container>
        </Box>
        <Box
          sx={{
            backgroundImage: `url(${vision_bg})`,
            backgroundSize: "cover", // Adjust as needed (contain, cover, etc.)
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            pt: "2rem",
          }}
        >
          <Container>
            <Grid container spacing={3} py={"1rem"}>
              <Grid item md={6} sm={12} xs={12}>
                <Typography
                  component={"img"}
                  src={Data_science_Img}
                  width={"100%"}
                />
              </Grid>
              <Grid
                item
                md={6}
                sm={12}
                xs={12}
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Box sx={{ textAlign: { md: "start", xs: "center" } }}>
                  <Typography component={"img"} src={data_science} />
                  <Typography
                    sx={{
                      fontFamily: "exoBold",
                      fontSize: { sm: "40px", xs: "30px" },
                      color: "rgb(0 0 0 / 100%)",
                      textAlign: { md: "start", xs: "center" },
                    }}
                  >
                    Data Science
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: "exoRegular",
                      fontSize: { sm: "16px", xs: "16px" },
                      color: "rgb(0 0 0 / 69%)",
                      textAlign: { md: "start", xs: "center" },
                    }}
                  >
                    Harness the power of your data with Ingenuity Analytics. Our
                    Data Science services turn raw data into actionable insights
                    through advanced analytics and machine learning. We help you
                    make informed decisions, optimize operations, and gain a
                    competitive edge, empowering your business to achieve its
                    full potential.
                  </Typography>
                  <Button>
                    <Typography
                      component={"img"}
                      src={contact_}
                      width={"18px"}
                      className="hover"
                    />{" "}
                    <Typography
                      component={"img"}
                      src={contact}
                      width={"18px"}
                      className="main"
                    />{" "}
                    Contact us
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Container>
        </Box>
        <Box
          sx={{
            background: "#F1FAFF",
            overflow: "hidden",
            pt: "1rem",
            pb: "0.5rem",
            boxShadow:
              "inset 0 4px 8px rgba(0, 0, 0, 0.1), inset 0 -4px 8px rgba(0, 0, 0, 0.1)",
          }}
        >
          <Typography
            component={"img"}
            src={Data_science_tools}
            sx={{ width: { md: "100%", sm: "180%", xs: "360%" } }}
          />
        </Box>
        <Box
          sx={{
            backgroundImage: `url(${robotics_bg})`,
            backgroundSize: "cover", // Adjust as needed (contain, cover, etc.)
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            py: "2rem",
          }}
        >
          <Container>
            <Grid container spacing={3} py={"1rem"}>
              <Grid
                item
                md={6}
                sm={12}
                xs={12}
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Box sx={{ textAlign: { md: "start", xs: "center" } }}>
                  <Typography component={"img"} src={robotics} />
                  <Typography
                    sx={{
                      fontFamily: "exoBold",
                      fontSize: { sm: "40px", xs: "30px" },
                      color: "rgb(0 0 0 / 100%)",
                      textAlign: { md: "start", xs: "center" },
                    }}
                  >
                    Robotic Process Automation
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: "exoRegular",
                      fontSize: { sm: "16px", xs: "16px" },
                      color: "rgb(0 0 0 / 69%)",
                      textAlign: { md: "start", xs: "center" },
                    }}
                  >
                    Streamline your operations with Ingenuity Analytics. Our
                    Robotics Process Automation (RPA) services automate
                    repetitive tasks, reduce operational costs, and minimize
                    errors. By implementing tailored RPA solutions, we help your
                    business enhance efficiency and productivity, allowing your
                    team to focus on strategic initiatives.
                  </Typography>
                  <Button>
                    <Typography
                      component={"img"}
                      src={contact_}
                      width={"18px"}
                      className="hover"
                    />{" "}
                    <Typography
                      component={"img"}
                      src={contact}
                      width={"18px"}
                      className="main"
                    />{" "}
                    Contact us
                  </Button>
                </Box>
              </Grid>
              <Grid item md={6} sm={12} xs={12}>
                <Typography
                  component={"img"}
                  src={Robotic_Img}
                  width={"100%"}
                />
              </Grid>
            </Grid>
          </Container>
        </Box>
        <Box
          sx={{
            background: "#F1FAFF",
            overflow: "hidden",
            pt: "1rem",
            pb: "0.5rem",
            boxShadow:
              "inset 0 4px 8px rgba(0, 0, 0, 0.1), inset 0 -4px 8px rgba(0, 0, 0, 0.1)",
          }}
        >
          <Typography
            component={"img"}
            src={Robotic_tools}
            sx={{ width: { md: "100%", sm: "180%", xs: "360%" } }}
          />
        </Box>
        <Box
          sx={{
            backgroundImage: `url(${vision_bg})`,
            backgroundSize: "cover", // Adjust as needed (contain, cover, etc.)
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            pt: "2rem",
          }}
        >
          <Container>
            <Grid container spacing={3} py={"1rem"}>
              <Grid item md={6} sm={12} xs={12}>
                <Typography component={"img"} src={Web_img} width={"100%"} />
              </Grid>
              <Grid
                item
                md={6}
                sm={12}
                xs={12}
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Box sx={{ textAlign: { md: "start", xs: "center" } }}>
                  <Typography component={"img"} src={web3} />
                  <Typography
                    sx={{
                      fontFamily: "exoBold",
                      fontSize: { sm: "40px", xs: "30px" },
                      color: "rgb(0 0 0 / 100%)",
                      textAlign: { md: "start", xs: "center" },
                    }}
                  >
                    Web3 Development
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: "exoRegular",
                      fontSize: { sm: "16px", xs: "16px" },
                      color: "rgb(0 0 0 / 69%)",
                      textAlign: { md: "start", xs: "center" },
                    }}
                  >
                    Empower your business with Ingenuity Analytics. Our Web3
                    Development services specialize in creating decentralized
                    applications and smart contracts using blockchain
                    technology. These solutions ensure secure, transparent, and
                    efficient processes, fostering innovation and trust within
                    your business operations. Stay ahead in the digital
                    landscape with our advanced Web3 solutions.
                  </Typography>
                  <Button>
                    <Typography
                      component={"img"}
                      src={contact_}
                      width={"18px"}
                      className="hover"
                    />{" "}
                    <Typography
                      component={"img"}
                      src={contact}
                      width={"18px"}
                      className="main"
                    />{" "}
                    Contact us
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Container>
        </Box>
        <Box
          sx={{
            background: "#F1FAFF",
            overflow: "hidden",
            mb: "2rem",
            pt: "1rem",
            pb: "0.5rem",
            boxShadow:
              "inset 0 4px 8px rgba(0, 0, 0, 0.1), inset 0 -4px 8px rgba(0, 0, 0, 0.1)",
          }}
        >
          <Typography
            component={"img"}
            src={Web3_tools}
            sx={{ width: { md: "100%", sm: "180%", xs: "360%" } }}
          />
        </Box>
        <Box
          sx={{
            backgroundImage: `url(${service_msg_bg})`,
            backgroundSize: "cover", // Adjust as needed (contain, cover, etc.)
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            py: "3rem",
          }}
        >
          <Container>
            <Grid container spacing={2}>
              <Grid item md={6} sm={12} xs={12}>
                <Typography
                  sx={{
                    fontFamily: "exoBold",
                    fontSize: { sm: "45px", xs: "30px" },
                    color: "rgb(0 0 0 / 100%)",
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                    // lineHeight: "2rem",
                  }}
                >
                  Have an idea?
                  <Typography
                    component={"img"}
                    src={arrow}
                    width={"50px"}
                    height={"50px"}
                    sx={{ transform: { md: "rotate(-90deg)" } }}
                  />
                </Typography>
                <Typography
                  component={"span"}
                  sx={{
                    fontFamily: "exoBold",
                    fontSize: { sm: "60px", xs: "35px" },
                    background:
                      "linear-gradient(to right,#E41111 0, #FF6666 22%, #53E71F 50%, #39BB0B 83%)",
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                  }}
                >
                  Let’s bring it in life!
                </Typography>
              </Grid>
              <Grid item md={6} sm={12} xs={12}>
                <AboutForm />
              </Grid>
            </Grid>
          </Container>
        </Box>
        <Footer />
      </Box>
    </Box>
  );
};

export default Services;
