import { Box, Button, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import logo from "../Assets/Images/IA.png";
import contact from "../Assets/Images/contact.svg";
import contact_ from "../Assets/Images/contact_.svg";

const MobileNav = ({ handleDrawerToggle,cartValue }) => {


  return (
    <Box sx={{
  "& .submenuList": {
    padding: "0.4rem",
    borderRadius: "4px",
    fontFamily: "exoMedium !important",
    "&:hover": {
      color: "#800080",
    },
  },
    }}>

      <Typography
              component={"img"}
              src={logo}
              sx={{
                width: { sm: "200px", xs: "200px" },
              }}
            />
      {/* <Typography variant="h5" color={"#fff"} fontFamily={"lato"} fontWeight={600}>LOGO</Typography> */}
      <IconButton
        aria-label="close"
        onClick={handleDrawerToggle}
        title="Close"
        sx={{
          position: "absolute",
          right: "5px",
          color: "#fff",
          top: "5px",
          // background: "#fff",
          "&:hover": {
            // background: "#fff",
          },
          // color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon sx={{ fontSize: "1.5rem" }} />
      </IconButton>
      <Box
        // onClick={handleDrawerToggle}
        sx={{
          // textAlign: "center",
          display: "grid",
          flexDirection: "column",
          alignItems: "center",
          gap: "0.1rem",
          mt: "2rem",
          textAlign:"center",
          "& a": {
            // color: "#800080",
            color: "#fff",
            textDecoration: "none",
          },
          "& a:hover": {
                      color: "#fff",
                    },
        }}
      >
        <Typography className="submenuList">
          <Link to={"/"}>Home</Link>
       </Typography>
        <Typography className="submenuList">
          <Link to={"/services"}>Services</Link>
       </Typography>
        <Typography className="submenuList">
          <Link to={"/about"}>About us</Link>
       </Typography>
        <Typography className="submenuList">
        <Button component={Link} to="/contact"
              sx={{
                textTransform: "capitalize",
                fontFamily: "exoBold",
                // lineHeight:"0",
                background: "#39BB0B",
                borderRadius: "5px",
                color: "#fff",
                fontSize: "16px",
                p: "8px 30px",
                gap: "0.5rem",
                "&:hover": {
                  background: "#53E71F",
                  "& .hover":{
                    display:"block"
                  },
                  "& .main":{
                    display:"none"
                  },
                },
                "& .hover":{
                  display:"none"
                },
              }}
            >
              <Typography component={"img"} src={contact_} width={"18px"} className="hover"/>{" "}
              <Typography component={"img"} src={contact} width={"18px"} className="main"/>{" "}
              Contact us
            </Button>
       </Typography>
     {/* <Typography pt={"0.5rem"}>  <CustomButton text={"Connect Wallet"} width={"100%"} /></Typography> */}
    
      </Box>
    </Box>
  );
};

export default MobileNav;
