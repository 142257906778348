import React from 'react'
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import PropTypes from "prop-types";
import { Box, Typography } from '@mui/material';


function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: "12px 16px 0", color: "#800080" }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          title="Close"
          sx={{
            position: "absolute",
            right: "-10px",
            color: "#fff",
            top: "-10px",
            background: "#39BB0B",
            // border:"1px solid #800080",
            borderRadius: "100%",
            "&:hover": {
              background: "#39BB0B",
            },
            // color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon sx={{ fontSize: "1.3rem" }} />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const SuccessModal = ({setShowSnackbar,showSnackbar}) => {
  return (
    <Dialog
    onClose={() => setShowSnackbar(false)}
    aria-labelledby="customized-dialog-title"
    open={showSnackbar}
    sx={{
      "& .MuiDialogContent-root": {
        padding: "1rem",
        border: "none",
      },
      "& .MuiDialogActions-root": {
        padding: "1rem",
      },
      "& .MuiPaper-root": {
        overflowY: "inherit",
      },
      "& .MuiDialog-paper": {
        width: "500px",
        maxWidth: "1222px",
        // background: "#E4D5F4",
        border: "2px solid #39BB0B",
        borderRadius: "10px",
      },
      "& .MuiDialog-container": {
        backdropFilter: "blur(5px)",
      },
    }}
>
<BootstrapDialogTitle
  id="customized-dialog-title"
  onClose={() => setShowSnackbar(false)}
>
  <Typography
    sx={{
      color: "green",
      fontSize: "1.2rem",
      borderBottom: "1px solid #39BB0B",
      pb: "0.3rem",
      mb: "0.3rem",
      display: "flex",
      alignItems: "center",
      gap: "0.1rem",
     
    }}
  >
    <TaskAltIcon sx={{ mb: "2px" }} />
    Success
  </Typography>
</BootstrapDialogTitle>
<DialogContent dividers>
  <Box
    sx={{
      textAlign: "center",
      py: "1.5rem",
    //   fontSize: "1.2rem",
      color: "green",
     
    }}
  >
   Thanks for showing interest,<br/> will get back to you
  </Box>
</DialogContent>
</Dialog>
  )
}

export default SuccessModal